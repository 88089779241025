import equal from 'fast-deep-equal';
import { createContext } from 'zustand-utils';
import { devtools } from 'zustand/middleware';
import { createWithEqualityFn } from 'zustand/traditional';
export var createStore = function createStore(initState) {
  return createWithEqualityFn()(devtools(function () {
    return initState;
  }, {
    name: 'dumi-theme-antd-style'
  }), equal);
};
var _createContext = createContext(),
  useStore = _createContext.useStore,
  useStoreApi = _createContext.useStoreApi,
  Provider = _createContext.Provider;
export { Provider, useStore as useSiteStore, useStoreApi };