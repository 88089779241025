import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { genMapTokenAlgorithm } from "../algorithms";
var lightMode = genMapTokenAlgorithm();
export var lightColorPalettes = lightMode.palettes;
export var lightTheme = {
  token: {
    colorBgLayout: '#f8f8fa',
    // Layout 颜色
    colorTextBase: '#2a2e36',
    colorLinkHover: lightColorPalettes.primary[5],
    colorLink: lightColorPalettes.primary[6],
    colorLinkActive: lightColorPalettes.primary[7]
  },
  algorithm: function algorithm(seedToken, mapToken) {
    return _objectSpread(_objectSpread({}, mapToken), lightMode.tokens);
  }
};