import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { generateAssociatedColors } from "./utils/colorRelationship";
import { generateColorPalette, generateNeutralPalette } from "./utils/paletteGenerator";
var defaultRelationship = function defaultRelationship(type) {
  var key = type.toUpperCase()[0] + type.slice(1);
  return _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, "color".concat(key, "Bg"), 1), "color".concat(key, "BgHover"), 2), "color".concat(key, "Border"), 3), "color".concat(key, "BorderHover"), 4), "color".concat(key, "Hover"), 5), "color".concat(key), 6), "color".concat(key, "Active"), 7), "color".concat(key, "TextHover"), 8), "color".concat(key, "Text"), 9), "color".concat(key, "TextActive"), 10);
};
export var genMapTokenAlgorithm = function genMapTokenAlgorithm(params) {
  var _ref2 = params || {},
    _ref2$relationship = _ref2.relationship,
    relationship = _ref2$relationship === void 0 ? defaultRelationship : _ref2$relationship,
    _ref2$infoColorFollow = _ref2.infoColorFollowPrimary,
    infoColorFollowPrimary = _ref2$infoColorFollow === void 0 ? false : _ref2$infoColorFollow,
    adjustWarning = _ref2.adjustWarning,
    _ref2$brandColor = _ref2.brandColor,
    brandColor = _ref2$brandColor === void 0 ? '#1677FF' : _ref2$brandColor;
  var funcColors = generateAssociatedColors(brandColor, adjustWarning);
  var seedColors = _objectSpread(_objectSpread({
    primary: brandColor
  }, funcColors), params === null || params === void 0 ? void 0 : params.seedColors);
  if (infoColorFollowPrimary) {
    seedColors.info = brandColor;
  }
  var palettes = {
    primary: generateColorPalette(seedColors.primary, params).map(function (color) {
      return color.hex;
    }),
    error: generateColorPalette(seedColors.error, params).map(function (c) {
      return c.hex;
    }),
    warning: generateColorPalette(seedColors.warning, params).map(function (c) {
      return c.hex;
    }),
    success: generateColorPalette(seedColors.success, params).map(function (c) {
      return c.hex;
    }),
    info: generateColorPalette(seedColors.info, params).map(function (color) {
      return color.hex;
    }),
    neutral: generateNeutralPalette(seedColors.primary, _objectSpread(_objectSpread({}, params), {}, {
      neutral: true
    })).map(function (c) {
      return c.hex;
    }),
    grey: generateNeutralPalette(seedColors.primary, params).map(function (c) {
      return c.hex;
    })
  };
  var tokens = {};
  var types = ['primary', 'error', 'warning', 'success', 'info'];
  types.forEach(function (type) {
    Object.entries(relationship(type)).forEach(function (_ref3) {
      var _ref4 = _slicedToArray(_ref3, 2),
        key = _ref4[0],
        value = _ref4[1];
      tokens[key] = palettes[type][value];
    });
  });
  return {
    palettes: palettes,
    tokens: tokens
  };
};