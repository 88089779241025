import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { theme } from 'antd';
import { genDarkMapTokenAlgorithm } from "../algorithms";
var cyanColors = ['rgba(0, 225, 242, 0.12)', 'rgba(0, 232, 245, 0.22)', 'rgba(0, 237, 250, 0.32)', 'rgba(0, 243, 255, 0.42)', 'rgba(0, 247, 255, 0.53)', 'rgba(0, 246, 254, 0.65)', 'rgba(0, 247, 253, 0.77)', 'rgba(0, 245, 255, 0.75)', 'rgba(0, 244, 255, 0.73)', 'rgba(0, 239, 253, 0.72)', 'rgba(0, 237, 253, 0.7)'];
var darkMode = genDarkMapTokenAlgorithm();
export var darkColorPalettes = darkMode.palettes;
var darkAlgorithm = function darkAlgorithm(seedToken, mapToken) {
  return _objectSpread(_objectSpread(_objectSpread({}, theme.darkAlgorithm(seedToken, mapToken)), darkMode.tokens), {}, {
    'cyan-1': cyanColors[1],
    'cyan-2': cyanColors[2],
    'cyan-3': cyanColors[3],
    'cyan-4': cyanColors[4],
    'cyan-5': cyanColors[5],
    'cyan-6': cyanColors[6],
    'cyan-7': cyanColors[7],
    'cyan-8': cyanColors[8],
    'cyan-9': cyanColors[9],
    'cyan-10': cyanColors[10]
  });
};
export var darkTheme = {
  token: {
    colorTextBase: '#c7ddff',
    colorBgBase: '#050608',
    colorLinkHover: darkColorPalettes.primary[7],
    colorLink: darkColorPalettes.primary[6],
    colorLinkActive: darkColorPalettes.primary[5]
  },
  algorithm: darkAlgorithm
};