"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _typescript = _interopRequireDefault(require("refractor/lang/typescript.js"));

;
var _default = _typescript["default"];
exports["default"] = _default;