import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import { merge } from 'lodash';
import { genMapTokenAlgorithm } from "./default";
var darkModeRelationship = function darkModeRelationship(type) {
  var key = type.toUpperCase()[0] + type.slice(1);
  return _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, "color".concat(key, "Bg"), 1), "color".concat(key, "BgHover"), 2), "color".concat(key, "Border"), 3), "color".concat(key, "BorderHover"), 4), "color".concat(key, "Hover"), 7), "color".concat(key), 6), "color".concat(key, "Active"), 5), "color".concat(key, "TextHover"), 7), "color".concat(key, "Text"), 6), "color".concat(key, "TextActive"), 5);
};
export var genDarkMapTokenAlgorithm = function genDarkMapTokenAlgorithm(params) {
  var defaultConfig = {
    lighter: {
      steps: 4,
      // 减少较亮颜色的数量
      targetBrightness: 0.7,
      // 降低最大亮度值
      saturationAdjustment: 0.6 // 减小较亮颜色的饱和度调整
      // saturationScale: 1,
    },
    darker: {
      steps: 6,
      // 增加较暗颜色的数量
      targetBrightness: 0.2,
      // 降低最小亮度值
      saturationAdjustment: 0.7,
      hueAdjustment: 0.9,
      // 保持暗色调的色相调整因子
      saturationScale: 1
    },
    reverse: true,
    relationship: darkModeRelationship
  };
  var config = merge({}, defaultConfig, params);
  return genMapTokenAlgorithm(config);
};