export var getPrefix = function getPrefix(prefixCls) {
  if (prefixCls) return prefixCls;
  return 'layoutkit';
};
export var getFlexDirection = function getFlexDirection(direction, isHorizontal) {
  if (isHorizontal) return 'row';
  switch (direction) {
    case 'horizontal':
      return 'row';
    case 'horizontal-reverse':
      return 'row-reverse';
    case 'vertical':
    default:
      return 'column';
    case 'vertical-reverse':
      return 'column-reverse';
  }
};
export var isSpaceDistribution = function isSpaceDistribution(distribution) {
  if (!distribution) return;
  return ['space-between', 'space-around', 'space-evenly'].includes(distribution);
};
export var isHorizontal = function isHorizontal(direction, _isHorizontal) {
  return getFlexDirection(direction, _isHorizontal) === 'row';
};
export var isVertical = function isVertical(direction, isHorizontal) {
  return getFlexDirection(direction, isHorizontal) === 'column';
};
export var getCssValue = function getCssValue(value) {
  return typeof value === 'number' ? "".concat(value, "px") : value;
};