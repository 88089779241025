// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import { frontmatter as fm0, toc as t0 } from '/home/runner/work/iscorrect/iscorrect/docs/cn/mobileNo.zh-CN.md?type=frontmatter';
import { demoIndex as dmi0 } from '/home/runner/work/iscorrect/iscorrect/docs/cn/mobileNo.zh-CN.md?type=demo-index';
import { frontmatter as fm1, toc as t1 } from '/home/runner/work/iscorrect/iscorrect/docs/cn/mobileNo.md?type=frontmatter';
import { demoIndex as dmi1 } from '/home/runner/work/iscorrect/iscorrect/docs/cn/mobileNo.md?type=demo-index';
import { frontmatter as fm2, toc as t2 } from '/home/runner/work/iscorrect/iscorrect/docs/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi2 } from '/home/runner/work/iscorrect/iscorrect/docs/index.zh-CN.md?type=demo-index';
import { frontmatter as fm3, toc as t3 } from '/home/runner/work/iscorrect/iscorrect/docs/index.md?type=frontmatter';
import { demoIndex as dmi3 } from '/home/runner/work/iscorrect/iscorrect/docs/index.md?type=demo-index';
import { frontmatter as fm4, toc as t4 } from '/home/runner/work/iscorrect/iscorrect/src/IP/index.md?type=frontmatter';
import { demoIndex as dmi4 } from '/home/runner/work/iscorrect/iscorrect/src/IP/index.md?type=demo-index';
import { frontmatter as fm5, toc as t5 } from '/home/runner/work/iscorrect/iscorrect/src/IP/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi5 } from '/home/runner/work/iscorrect/iscorrect/src/IP/index.zh-CN.md?type=demo-index';

export const filesMeta = {
  'docs/cn/mobileNo.zh-CN': {
    frontmatter: fm0,
    toc: t0,
    demoIndex: dmi0,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/home/runner/work/iscorrect/iscorrect/docs/cn/mobileNo.zh-CN.md?type=text'),
  },
  'docs/cn/mobileNo': {
    frontmatter: fm1,
    toc: t1,
    demoIndex: dmi1,
    textGetter: () => import(/* webpackChunkName: "meta__docs" */'/home/runner/work/iscorrect/iscorrect/docs/cn/mobileNo.md?type=text'),
  },
  'docs/index.zh-CN': {
    frontmatter: fm2,
    toc: t2,
    demoIndex: dmi2,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/home/runner/work/iscorrect/iscorrect/docs/index.zh-CN.md?type=text'),
  },
  'docs/index': {
    frontmatter: fm3,
    toc: t3,
    demoIndex: dmi3,
    textGetter: () => import(/* webpackChunkName: "meta__docs" */'/home/runner/work/iscorrect/iscorrect/docs/index.md?type=text'),
  },
  'components/IP/index': {
    frontmatter: fm4,
    toc: t4,
    demoIndex: dmi4,
    textGetter: () => import(/* webpackChunkName: "meta__IP" */'/home/runner/work/iscorrect/iscorrect/src/IP/index.md?type=text'),
  },
  'components/IP/index.zh-CN': {
    frontmatter: fm5,
    toc: t5,
    demoIndex: dmi5,
    textGetter: () => import(/* webpackChunkName: "meta__IP__zh-CN" */'/home/runner/work/iscorrect/iscorrect/src/IP/index.zh-CN.md?type=text'),
  },
}

export { tabs as tabsMeta } from './tabs';
